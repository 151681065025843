<template>
   <Accounts />
</template>

<script>
import Accounts from "../components/Accounts/Accounts.vue";

export default {
   components: {
      Accounts,
   },
};
</script>

<style></style>
