<template>
   <v-dialog v-model="dialog" max-width="800px">
      <Notification type="error" message="Error" ref="error_noti" />
      <v-card :loading="isloading">
         <v-card-title>{{ title }}</v-card-title>
         <v-card-text>
            <form>
               <v-row>
                  <v-col cols="12" md="6">
                     <v-text-field
                        v-model="nombre"
                        label="Nombre"
                        :error-messages="nombreErrors"
                        required
                        @input="$v.nombre.$touch()"
                        @blur="$v.nombre.$touch()"
                     ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                     <v-text-field
                        v-model="empresa"
                        label="Empresa"
                        :error-messages="empresaErrors"
                        required
                        @input="$v.empresa.$touch()"
                        @blur="$v.empresa.$touch()"
                     ></v-text-field>
                  </v-col>
               </v-row>
               <v-row>
                  <v-col cols="12" md="6">
                     <v-text-field
                        v-model="rfc"
                        label="RFC"
                        :error-messages="rfcErrors"
                        required
                        @input="$v.rfc.$touch()"
                        @blur="$v.rfc.$touch()"
                     ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                     <v-text-field
                        v-model="telefono"
                        label="Telefono"
                        type="number"
                        :error-messages="telefonoErrors"
                        required
                        @input="$v.telefono.$touch()"
                        @blur="$v.telefono.$touch()"
                     ></v-text-field>
                  </v-col>
               </v-row>
               <v-row>
                  <v-col cols="12" md="6">
                     <v-text-field
                        v-model="email"
                        label="Email"
                        :error-messages="emailErrors"
                        required
                        @input="$v.email.$touch()"
                        @blur="$v.email.$touch()"
                     ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                     <v-text-field
                        v-model="fondo"
                        label="Fondo"
                        type="number"
                        :disabled="isEdit"
                        :error-messages="fondoErrors"
                        required
                        prepend-icon="mdi-currency-usd"
                        @input="$v.fondo.$touch()"
                        @blur="$v.fondo.$touch()"
                     ></v-text-field>
                  </v-col>
               </v-row>
               <v-row>
                  <v-col cols="12" md="6">
                     <v-text-field
                        v-model="nip"
                        label="NIP"
                        :error-messages="nipErrors"
                        :disabled="isEdit"
                        :type="show_nip ? 'text' : 'password'"
                        :append-icon="show_nip ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show_nip = !show_nip"
                        @input="$v.nip.$touch()"
                        @blur="$v.nip.$touch()"
                     ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                     <v-text-field
                        v-model="repeat_nip"
                        label="Repetir NIP"
                        :error-messages="repeat_nipErrors"
                        :disabled="isEdit"
                        :type="show_rnip ? 'text' : 'password'"
                        :append-icon="show_rnip ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show_rnip = !show_rnip"
                        @input="$v.repeat_nip.$touch()"
                        @blur="$v.repeat_nip.$touch()"
                     ></v-text-field>
                  </v-col>
               </v-row>
            </form>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">Cancelar</v-btn>
            <v-btn :loading="isloading" color="primary" @click="save">Guardar</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, minLength, email, integer } from "vuelidate/lib/validators";

export default {
   mixins: [validationMixin],
   validations: {
      nombre: {
         required,
         maxLength: maxLength(30),
         minLength: minLength(5),
      },
      empresa: {
         required,
         maxLength: maxLength(30),
         minLength: minLength(5),
      },
      rfc: {
         required,
         maxLength: maxLength(20),
         minLength: minLength(10),
      },
      telefono: {
         required,
         maxLength: maxLength(10),
         minLength: minLength(10),
         integer,
         positivo: function () {
            return this.telefono > 0;
         },
      },
      email: {
         required,
         email,
      },
      fondo: {
         required,
         positivo: function () {
            return this.fondo >= 0;
         },
         number: function () {
            return !isNaN(this.fondo);
         },
      },
      nip: {
         required: function () {
            if (this.isEdit) {
               return true;
            } else {
               if (this.nip.trim().length > 0) {
                  return true;
               }
            }
            return false;
         },
         integer: function(){
            return !Number.isInteger(this.nip) || this.isEdit;
         },
         Length: function(){
            return this.nip.length == 6 || this.isEdit;
         },
         positivo: function () {
            return this.nip > 0 || this.isEdit;
         },
         number: function () {
            return !isNaN(this.nip) || this.isEdit;
         },
         notZeroStart: function () {
            return this.nip >= 100000 || this.isEdit;
         },
      },
      repeat_nip: {
         same: function () {
            return this.repeat_nip == this.nip;
         },
      },
   },
   data: function () {
      return {
         cuenta_id: null,
         nombre: "",
         empresa: "",
         rfc: "",
         telefono: "",
         email: "",
         fondo: 0,
         nip: "",
         repeat_nip: "",
         show_nip: false,
         show_rnip: false,
         dialog: false,
         isloading: false,
      };
   },
   watch: {
      dialog: function (val) {
         if (!val) {
            this.close();
            this.$emit("close");
         }
      },
   },
   methods: {
      save: async function () {
         try {
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;
               let res = null;
               if (!this.isEdit) {
                  res = await this.$store.dispatch("insertCuenta", {
                     nombre: this.nombre,
                     empresa: this.empresa,
                     rfc: this.rfc,
                     telefono: this.telefono,
                     email: this.email,
                     fondo: this.fondo,
                     nip: this.nip,
                  });
               } else {
                  res = await this.$store.dispatch("updateCuenta", {
                     cuenta_id: this.cuenta_id,
                     nombre: this.nombre,
                     empresa: this.empresa,
                     rfc: this.rfc,
                     telefono: this.telefono,
                     email: this.email,
                  });
               }
               if (res == true) {
                  this.$emit("save");
                  this.close();
               } else {
                  throw res;
               }
            } else {
               throw "error";
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      close: function () {
         this.dialog = false;
         this.$v.$reset();
      },
      show: function (i) {
         this.dialog = true;
         this.cuenta_id = i.cuenta_id;
         this.nombre = i.nombre;
         this.empresa = i.empresa;
         this.rfc = i.rfc;
         this.telefono = i.telefono;
         this.email = i.email;
         this.fondo = i.fondo;
      },
   },
   computed: {
      isEdit() {
         if (this.cuenta_id) {
            return true;
         } else {
            return false;
         }
      },
      title() {
         if (this.cuenta_id) {
            return "Editar Cuenta";
         } else {
            return "Crear Cuenta";
         }
      },
      nombreErrors() {
         const errors = [];
         if (!this.$v.nombre.$dirty) return errors;
         !this.$v.nombre.required && errors.push("El nombre es requerido");
         !this.$v.nombre.maxLength && errors.push("Debe contener maximo 30 caracteres");
         !this.$v.nombre.minLength && errors.push("Debe contener minimo 5 caracteres");
         return errors;
      },
      empresaErrors() {
         const errors = [];
         if (!this.$v.empresa.$dirty) return errors;
         !this.$v.empresa.required && errors.push("Es requerido");
         !this.$v.empresa.maxLength && errors.push("Debe contener maximo 30 caracteres");
         !this.$v.empresa.minLength && errors.push("Debe contener minimo 5 caracteres");
         return errors;
      },
      rfcErrors() {
         const errors = [];
         if (!this.$v.rfc.$dirty) return errors;
         !this.$v.rfc.required && errors.push("Es requerido");
         !this.$v.rfc.maxLength && errors.push("Debe contener maximo 20 caracteres");
         !this.$v.rfc.minLength && errors.push("Debe contener minimo 10 caracteres");
         return errors;
      },
      telefonoErrors() {
         const errors = [];
         if (!this.$v.telefono.$dirty) return errors;
         !this.$v.telefono.required && errors.push("Es requerido");
         !this.$v.telefono.maxLength && errors.push("Debe contener 10 caracteres");
         !this.$v.telefono.minLength && errors.push("Debe contener 10 caracteres");
         !this.$v.telefono.integer && errors.push("Debe contener solo valores numericos para telefono");
         !this.$v.telefono.positivo && errors.push("No incluir otros simbolos");
         return errors;
      },
      emailErrors() {
         const errors = [];
         if (!this.$v.email.$dirty) return errors;
         !this.$v.email.required && errors.push("Es requerido");
         !this.$v.email.email && errors.push("Debe ser un email valido");
         return errors;
      },
      fondoErrors() {
         const errors = [];
         if (!this.$v.fondo.$dirty) return errors;
         !this.$v.fondo.required && errors.push("Es requerido");
         !this.$v.fondo.positivo && errors.push("Debe ser un numero positivo");
         !this.$v.fondo.number && errors.push("Indique un numero valido");
         return errors;
      },
      nipErrors() {
         const errors = [];
         if (!this.$v.nip.$dirty) return errors;
         !this.$v.nip.required && errors.push("Es requerido");
         !this.$v.nip.Length && errors.push("Debe contener 6 caracteres");
         !this.$v.nip.positivo && errors.push("Debe ser un numero positivo");
         !this.$v.nip.number && errors.push("Indique un numero valido");
         !this.$v.nip.integer && errors.push("Indique un numero entero");
         !this.$v.nip.notZeroStart && errors.push("No valido");
         return errors;
      },
      repeat_nipErrors() {
         const errors = [];
         if (!this.$v.repeat_nip.$dirty) return errors;
         !this.$v.repeat_nip.same && errors.push("Los NIPs no coinciden");
         return errors;
      },
   },
};
</script>
