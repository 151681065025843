<template>
   <div>
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <Notification type="error" message="Error" ref="error_noti" />
      <Dialog message="¿Eliminar Subcuenta?" ref="delete" @result_ok="confirmDeleteItem" />
      <Subaccount
         ref="subaccount"
         @save="
            $refs.ok_noti.Show();
            Show(cuenta);
         "
      />
      <Nip ref="nip" :isCuenta="false" @save="$refs.ok_noti.Show()" />
      <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
         <v-card :loading="isloading">
            <v-toolbar elevation="2" color="secondary">
               <v-btn icon @click="close">
                  <v-icon>mdi-close</v-icon>
               </v-btn>
               <v-toolbar-title>{{ title }}</v-toolbar-title>
               <v-spacer></v-spacer>
               <v-btn @click="newItem(cuenta)" :loading="isloading"> <v-icon left>mdi-plus-circle</v-icon>Nueva subcuenta </v-btn>
            </v-toolbar>
            <v-card-text>
               <v-card class="ma-2" elevation="3" v-for="(item, index) in $store.state.subcuentas" :key="index">
                  <v-toolbar dense elevation="0">
                     {{ cuenta.cuenta }}-{{ item.consecutivo }}
                     <v-spacer></v-spacer>
                     <v-btn @click="editItem(item)" small class="mr-1" icon><v-icon small>mdi-pencil</v-icon></v-btn>
                     <v-btn v-if="$permiso('actualizar_nip_de_subcuentas', false)" @click="$refs.nip.Show(item)" small class="mr-1" icon><v-icon small>mdi-form-textbox-password</v-icon></v-btn>
                     <v-btn @click="deleteItem(item)" small class="mr-1" icon><v-icon small>mdi-trash-can</v-icon></v-btn>
                  </v-toolbar>
                  <v-card-text>
                     Nombre: {{ item.nombre }}
                     <br />
                     Máximo: ${{ item.max }}
                  </v-card-text>
               </v-card>
            </v-card-text>
         </v-card>
      </v-dialog>
   </div>
</template>

<script>
import Subaccount from "./Subaccount.vue";
import Nip from "./Nip.vue";

export default {
   components: {
      Subaccount,
      Nip,
   },
   data: function () {
      return {
         title: "",
         cuenta: {},
         itemToDelete: {},
         isloading: false,
         dialog: false,
      };
   },
   watch: {
      dialog: function () {
         if (!this.dialog) {
            this.close();
         }
      },
   },
   methods: {
      close: function () {
         this.dialog = false;
      },
      Show: async function (cuenta) {
         this.dialog = true;
         this.cuenta = cuenta;
         this.title = `Subcuentas de ${cuenta.nombre} (${cuenta.cuenta})`;
         try {
            this.isloading = true;
            await this.$store.dispatch("getSubcuentas", { cuenta_id: cuenta.cuenta_id });
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      async newItem(item) {
         if (this.$permiso("insertar_subcuentas"))
            this.$refs.subaccount.show({
               cuenta_id: item.cuenta_id,
               nombre: "",
               max: 0,
            });
      },
      async editItem(item) {
         if (this.$permiso("actualizar_subcuentas"))
            this.$refs.subaccount.show({
               subcuenta_id: item.subcuenta_id,
               nombre: item.nombre,
               max: item.max,
            });
      },
      async deleteItem(item) {
         if (this.$permiso("eliminar_subcuentas")) {
            this.itemToDelete = item;
            this.$refs.delete.Show();
         }
      },
      async confirmDeleteItem() {
         try {
            this.isloading = true;
            let res = null;
            res = await this.$store.dispatch("deleteSubcuenta", {
               subcuenta_id: this.itemToDelete.subcuenta_id,
               cuenta_id: this.itemToDelete.cuenta_id,
            });
            if (res == true) {
               this.$refs.ok_noti.Show();
            } else {
               throw res;
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
   },
};
</script>
