import moment from "moment";

export const printCuenta = (item) => {
    let elements = [
        {
           type: "text",
           title: "",
           text: moment().format("DD-MM-YYYY  hh:mm:ss a"),
           show: true,
        },
        {
           type: "divider",
           title: "",
           text: "",
           show: true,
        },
        {
           type: "text",
           title: "Cuenta:",
           text: item.cuenta,
           show: true,
        },
        {
           type: "text",
           title: "Nombre:",
           text: item.nombre,
           show: true,
        },
        {
           type: "text",
           title: "Empresa:",
           text: item.empresa,
           show: true,
        },
        {
           type: "text",
           title: "RFC:",
           text: item.rfc,
           show: true,
        },
        {
           type: "text",
           title: "Telefono:",
           text: item.telefono,
           show: true,
        },
     ];
   var win = window.open("", "PRINT", "height=600,width=600");

   let divs = "";
   elements.forEach((element) => {
      if(element.show){
         if (element.type == "divider") {
            divs += "<div>----------------</div>";
         } else if (element.type == "text") {
            divs += `<div class="ticket-text"><span class="ticket-accent">${element.title}</span>${element.text}</div>`;
         }
      }
   });

   let html =
      /*html*/
      `<html>
    <head>
        <meta charset="UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>PrintTicket</title>
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=VT323&display=swap" rel="stylesheet">
    </head>
    <body style="max-width: 300px;">
        <style scoped>
        .ticket-text {
            font-family: "VT323", monospace;
            line-height: 18px;
            font-size: 20px;
        }
        .ticket-accent {
            font-weight: bold;
        }
        .img{
            height: 96px;
            margin: 0 calc(50% - 48px);
        }
        </style>
        <h1 class="ticket-text" style="text-align: center">MADSA BANK</h1>
        ${divs}
    </body>
    </html>`;

   win.document.write(html);
   win.document.close();
   win.focus();
   win.print();
};
