<template>
   <v-dialog v-model="dialog" max-width="400px">
      <Notification type="error" message="Error" ref="error_noti" />
      <v-card :loading="isloading">
         <v-card-title>Cambiar NIP</v-card-title>
         <v-card-text>
            <form>
               <v-text-field
                  v-model="nip"
                  label="NIP"
                  :error-messages="nipErrors"
                  :type="show_nip ? 'text' : 'password'"
                  :append-icon="show_nip ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show_nip = !show_nip"
                  @input="$v.nip.$touch()"
                  @blur="$v.nip.$touch()"
               ></v-text-field>
               <v-text-field
                  v-model="repeat_nip"
                  label="Repetir NIP"
                  :error-messages="repeat_nipErrors"
                  :type="show_rnip ? 'text' : 'password'"
                  :append-icon="show_rnip ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show_rnip = !show_rnip"
                  @input="$v.repeat_nip.$touch()"
                  @blur="$v.repeat_nip.$touch()"
               ></v-text-field>
            </form>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">Cancelar</v-btn>
            <v-btn :loading="isloading" color="primary" @click="save">Guardar</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, integer } from "vuelidate/lib/validators";

export default {
   props: {
      isCuenta: Boolean,
   },
   mixins: [validationMixin],
   validations: {
      nip: {
         required,
         integer,
         Length: function () {
            return this.nip.length == 6;
         },
         positivo: function () {
            return this.nip > 0;
         },
         number: function () {
            return !isNaN(this.nip);
         },
         notZeroStart: function () {
            return this.nip >= 100000;
         },
      },
      repeat_nip: {
         same: function () {
            return this.repeat_nip == this.nip;
         },
      },
   },
   data: function () {
      return {
         cuenta_id: null,
         subcuenta_id: null,
         nip: "",
         repeat_nip: "",
         show_nip: false,
         show_rnip: false,
         dialog: false,
         isloading: false,
      };
   },
   watch: {
      dialog: function (val) {
         if (!val) {
            this.close();
            this.$emit("close");
         }
      },
   },
   methods: {
      save: async function () {
         try {
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;
               let res = null;
               if (this.isCuenta) {
                  res = await this.$store.dispatch("nipCuenta", {
                     cuenta_id: this.cuenta_id,
                     nip: this.nip,
                  });
               } else {
                  res = await this.$store.dispatch("nipSubcuenta", {
                     subcuenta_id: this.subcuenta_id,
                     cuenta_id: this.cuenta_id,
                     nip: this.nip,
                  });
               }
               if (res == true) {
                  this.$emit("save");
                  this.close();
               } else {
                  throw res;
               }
            } else {
               throw "error";
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      close: function () {
         this.dialog = false;
         this.$v.$reset();
      },
      Show: function (i) {
         this.nip = "";
         this.repeat_nip = "";
         this.dialog = true;
         if (i.cuenta_id) {
            this.cuenta_id = i.cuenta_id;
         }
         if (i.subcuenta_id) {
            this.subcuenta_id = i.subcuenta_id;
         }
      },
   },
   computed: {
      nipErrors() {
         const errors = [];
         if (!this.$v.nip.$dirty) return errors;
         !this.$v.nip.required && errors.push("Es requerido");
         !this.$v.nip.Length && errors.push("Debe contener 6 caracteres");
         !this.$v.nip.positivo && errors.push("Debe ser un numero positivo");
         !this.$v.nip.number && errors.push("Indique un numero valido");
         !this.$v.nip.integer && errors.push("Indique un numero entero");
         !this.$v.nip.notZeroStart && errors.push("No valido");
         return errors;
      },
      repeat_nipErrors() {
         const errors = [];
         if (!this.$v.repeat_nip.$dirty) return errors;
         !this.$v.repeat_nip.same && errors.push("Los NIPs no coinciden");
         return errors;
      },
   },
};
</script>
