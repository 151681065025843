<template>
   <div class="ma-6">
      <Account @save="$refs.ok_noti.Show()" ref="cuenta" />
      <Dialog message="¿Desea eliminar la cuenta?" @result_ok="confirmDeleteItem()" ref="deleteItem" />
      <Dialog message="¿Desea expostar a .CSV todas las cuentas?" @result_ok="confirmExport()" ref="export" />
      <Notification type="error" message="Error" ref="error_noti" />
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <Subaccounts ref="subaccount" />
      <Nip ref="nip" :isCuenta="true" @save="$refs.ok_noti.Show()" />
      <v-data-table
         :loading="isloading"
         :headers="headers"
         :items="$store.state.cuentas.items"
         :items-per-page="15"
         class="elevation-1"
         item-key="cuenta_id"
         :options.sync="options"
         :page.sync="page"
         :server-items-length="totalItems"
         :footer-props="{
            'items-per-page-options': [10, 30, 50, 100],
         }"
      >
         <template v-slot:top>
            <v-toolbar flat color="secondary">
               <v-btn @click="newItem()"><v-icon left>mdi-plus-circle</v-icon>Nueva cuenta</v-btn>
               <v-btn v-if="$permiso('exportar_cuentas', false)" @click="$refs.export.Show()" class="ml-2"><v-icon left>mdi-microsoft-excel</v-icon>Exportar</v-btn>
               <v-btn @click="initialize" icon small class="ml-2"><v-icon small>mdi-autorenew</v-icon></v-btn>
               <v-spacer></v-spacer>
               <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                  @input="search = search.slice(0, 30)"
               ></v-text-field>
            </v-toolbar>
         </template>
         <template v-slot:[`item.fondo`]="{ item }"> ${{ item.fondo }} </template>
         <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon v-if="$permiso('consultar_subcuentas', false)" small class="mr-2" @click="$refs.subaccount.Show(item)">mdi-account-cash</v-icon>
            <v-icon v-if="$permiso('actualizar_nip_de_cuenta', false)" small class="mr-2" @click="$refs.nip.Show(item)"
               >mdi-form-textbox-password</v-icon
            >
            <v-icon class="mr-2" small @click="printItem(item)">mdi-printer</v-icon>
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
         </template>
      </v-data-table>
   </div>
</template>

<script>
import Account from "./Account/Account.vue";
import Subaccounts from "./Account/Subaccounts.vue";
import Nip from "./Account/Nip.vue";
import fileDownload from "js-file-download";
import { printCuenta } from "../../modules/printCuenta";

export default {
   components: {
      Account,
      Subaccounts,
      Nip,
   },
   data() {
      return {
         headers: [
            { text: "ID", value: "cuenta_id" },
            { text: "Cuenta", value: "cuenta" },
            { text: "Nombre", value: "nombre" },
            { text: "Empresa", value: "empresa" },
            { text: "RFC", value: "rfc" },
            { text: "Telefono", value: "telefono" },
            { text: "Fondo", value: "fondo" },
            { text: "Acciones", value: "actions" },
         ],
         itemToDelete: null,
         isloading: true,
         search: "",
         totalItems: 0,
         page: 1,
         options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ["cuenta_id"],
            sortDesc: [true],
         },
      };
   },
   watch: {
      options: async function () {
         await this.initialize();
      },
      search: async function () {
         this.page = 1;
         await this.initialize();
      },
   },
   methods: {
      printItem(item){
         printCuenta(item);
      },
      async initialize() {
         try {
            this.isloading = true;
            await this.$store.dispatch("getCuentas", {
               page: this.options.page,
               itemsPerPage: this.options.itemsPerPage,
               sortBy: this.options.sortBy[0] ?? "",
               sortDesc: this.options.sortDesc[0] ?? false,
               search: this.search,
            });
            this.rows = this.$store.state.cuentas.items;
            this.totalItems = +this.$store.state.cuentas.totalItems;
            localStorage.setItem("cuentas_options", JSON.stringify(this.options));
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      async newItem() {
         if (this.$permiso("insertar_cuentas"))
            this.$refs.cuenta.show({
               cuenta_id: null,
               nombre: "",
               empresa: "",
               rfc: "",
               telefono: "",
               email: "",
               fondo: 0,
            });
      },
      async editItem(item) {
         if (this.$permiso("actualizar_cuentas"))
            this.$refs.cuenta.show({
               cuenta_id: item.cuenta_id,
               nombre: item.nombre,
               empresa: item.empresa,
               rfc: item.rfc,
               telefono: item.telefono,
               email: item.email,
               fondo: item.fondo,
            });
      },
      async deleteItem(item) {
         if (this.$permiso("eliminar_cuentas")) {
            this.itemToDelete = item;
            this.$refs.deleteItem.Show();
         }
      },
      async confirmDeleteItem() {
         try {
            this.isloading = true;
            let res = null;
            res = await this.$store.dispatch("deleteCuenta", {
               cuenta_id: this.itemToDelete.cuenta_id,
            });
            if (res == true) {
               this.$refs.ok_noti.Show();
            } else {
               throw res;
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      async confirmExport() {
         try {
            this.isloading = true;
            let res = null;

            res = await this.$store.dispatch("exportCuentas");

            if (res.data) {
               fileDownload(res.data, "cuentas.csv");
            } else {
               throw res;
            }

         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
   },
   created() {
      try {
         if (localStorage.getItem("cuentas_options")) {
            this.options = JSON.parse(localStorage.getItem("cuentas_options"));
         } else {
            this.options = {
               page: 1,
               itemsPerPage: 10,
               sortBy: ["cuenta_id"],
               sortDesc: [true],
            };
            localStorage.setItem("cuentas_options", JSON.stringify(this.options));
         }
      } catch (error) {
         this.options = {
            page: 1,
            itemsPerPage: 10,
            sortBy: ["cuenta_id"],
            sortDesc: [true],
         };
         localStorage.setItem("cuentas_options", JSON.stringify(this.options));
      } finally {
         this.initialize();
      }
   },
};
</script>

<style></style>
