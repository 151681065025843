<template>
   <v-dialog v-model="dialog" max-width="800px">
      <Notification type="error" message="Error" ref="error_noti" />
      <v-card :loading="isloading">
         <v-card-title>{{ title }}</v-card-title>
         <v-card-text>
            <form>
               <v-row>
                  <v-col cols="12" md="6">
                     <v-text-field
                        v-model="nombre"
                        label="Nombre"
                        :error-messages="nombreErrors"
                        required
                        @input="$v.nombre.$touch()"
                        @blur="$v.nombre.$touch()"
                     ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                     <v-text-field
                        v-model="max"
                        label="Máximo"
                        type="number"
                        :error-messages="maxErrors"
                        required
                        prepend-icon="mdi-currency-usd"
                        @input="$v.max.$touch()"
                        @blur="$v.max.$touch()"
                     ></v-text-field>
                  </v-col>
               </v-row>
               <v-row>
                  <v-col cols="12" md="6">
                     <v-text-field
                        v-model="nip"
                        label="NIP"
                        :error-messages="nipErrors"
                        :disabled="isEdit"
                        :type="show_nip ? 'text' : 'password'"
                        :append-icon="show_nip ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show_nip = !show_nip"
                        @input="$v.nip.$touch()"
                        @blur="$v.nip.$touch()"
                     ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                     <v-text-field
                        v-model="repeat_nip"
                        label="Repetir NIP"
                        :error-messages="repeat_nipErrors"
                        :disabled="isEdit"
                        :type="show_rnip ? 'text' : 'password'"
                        :append-icon="show_rnip ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show_rnip = !show_rnip"
                        @input="$v.repeat_nip.$touch()"
                        @blur="$v.repeat_nip.$touch()"
                     ></v-text-field>
                  </v-col>
               </v-row>
            </form>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">Cancelar</v-btn>
            <v-btn :loading="isloading" color="primary" @click="save">Guardar</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, minLength } from "vuelidate/lib/validators";

export default {
   mixins: [validationMixin],
   validations: {
      nombre: {
         required,
         maxLength: maxLength(30),
         minLength: minLength(5),
      },
      max: {
         required,
         positivo: function () {
            return this.max >= 0;
         },
         number: function () {
            return !isNaN(this.max);
         },
      },
      nip: {
         required: function () {
            if (this.isEdit) {
               return true;
            } else {
               if (this.nip.trim().length > 0) {
                  return true;
               }
            }
            return false;
         },
         integer: function(){
            return !Number.isInteger(this.nip) || this.isEdit;
         },
         Length: function(){
            return this.nip.length == 6 || this.isEdit;
         },
         positivo: function () {
            return this.nip > 0 || this.isEdit;
         },
         number: function () {
            return !isNaN(this.nip) || this.isEdit;
         },
         notZeroStart: function () {
            return this.nip >= 100000 || this.isEdit;
         },
      },
      repeat_nip: {
         same: function () {
            return this.repeat_nip == this.nip;
         },
      },
   },
   data: function () {
      return {
         cuenta_id: null,
         subcuenta_id: null,
         nombre: "",
         max: 0,
         nip: "",
         repeat_nip: "",
         show_nip: false,
         show_rnip: false,
         dialog: false,
         isloading: false,
      };
   },
   watch: {
      dialog: function (val) {
         if (!val) {
            this.close();
            this.$emit("close");
         }
      },
   },
   methods: {
      save: async function () {
         try {
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;
               let res = null;
               if (!this.isEdit) {
                  res = await this.$store.dispatch("insertSubcuenta", {
                     cuenta_id: this.cuenta_id,
                     nombre: this.nombre,
                     max: this.max,
                     nip: this.nip,
                  });
               } else {
                  res = await this.$store.dispatch("updateSubcuenta", {
                     subcuenta_id: this.subcuenta_id,
                     cuenta_id: this.cuenta_id,
                     nombre: this.nombre,
                     max: this.max,
                  });
               }
               if (res == true) {
                  this.$emit("save");
                  this.close();
               } else {
                  throw res;
               }
            } else {
               throw "error";
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      close: function () {
         this.dialog = false;
         this.$v.$reset();
      },
      show: function (i) {
         this.dialog = true;
         this.cuenta_id = i.cuenta_id;
         this.subcuenta_id = i.subcuenta_id;
         this.nombre = i.nombre;
         this.max = i.max;
      },
   },
   computed: {
      isEdit() {
         if (this.subcuenta_id) {
            return true;
         } else {
            return false;
         }
      },
      title() {
         if (this.subcuenta_id) {
            return "Editar Subcuenta";
         } else {
            return "Crear Subcuenta";
         }
      },
      nombreErrors() {
         const errors = [];
         if (!this.$v.nombre.$dirty) return errors;
         !this.$v.nombre.required && errors.push("El nombre es requerido");
         !this.$v.nombre.maxLength && errors.push("Debe contener maximo 30 caracteres");
         !this.$v.nombre.minLength && errors.push("Debe contener minimo 5 caracteres");
         return errors;
      },
      maxErrors() {
         const errors = [];
         if (!this.$v.max.$dirty) return errors;
         !this.$v.max.required && errors.push("Es requerido");
         !this.$v.max.positivo && errors.push("Debe ser un numero positivo");
         !this.$v.max.number && errors.push("Indique un numero valido");
         return errors;
      },
      nipErrors() {
         const errors = [];
         if (!this.$v.nip.$dirty) return errors;
         !this.$v.nip.required && errors.push("Es requerido");
         !this.$v.nip.Length && errors.push("Debe contener 6 caracteres");
         !this.$v.nip.positivo && errors.push("Debe ser un numero positivo");
         !this.$v.nip.number && errors.push("Indique un numero valido");
         !this.$v.nip.integer && errors.push("Indique un numero entero");
         !this.$v.nip.notZeroStart && errors.push("No valido");
         return errors;
      },
      repeat_nipErrors() {
         const errors = [];
         if (!this.$v.repeat_nip.$dirty) return errors;
         !this.$v.repeat_nip.same && errors.push("Los NIPs no coinciden");
         return errors;
      },
   },
};
</script>
